import urljoin from 'url-join'
import { API_URL } from './config'
import axios from 'axios'

const getHeaders = (token) => {
  const headers = {
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  return headers
}

const api = {

  login: async (email) => {
    const data = { email }
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/login'), data, config)
      return {
        status: res.status,
        data: res
      }
    }
    catch (e) {
      console.log('Error logging in', e)
      return null
    }
  },

  token: async (data) => {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/token'), data, config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error token request', e)
      return null
    }
  },

  signup: async (data) => {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/signup'), data, config)
      return {
        status: res.status,
        data: res
      }
    }
    catch (e) {
      console.log('Error signup', e)
      return null
    }
  },

  bankLink: async (webJWT, data) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/bank-link'), data, config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error bank-link', e)
      return null
    }
  },

  compute: async (webJWT) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios.post(
        urljoin(API_URL, 'compute/vs4plus'),
        {},
        config
      )
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error compute', e)
      return null
    }
  },

  score: async (webJWT, data) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios.post(
        urljoin(API_URL, '/vs4/tu'),
        data,
        config
      )
      return {
        status: res.status,
        data: res
      }
    }
    catch (e) {
      console.log('Error tu score', e)
      return null
    }
  },

  profile: async (webJWT) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios(urljoin(API_URL, '/user-profile'), config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error signup', e)
      return null
    }
  },

  accounts: async (webJWT) => {
    const config = {
      headers: getHeaders(webJWT)
    }
    try {
      const res = await axios(urljoin(API_URL, '/accounts'), config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error signup', e)
      return null
    }
  },

}

export default api
